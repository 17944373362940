<template>
    <front-layout>
        <div class="FaqArticle intro wrapper">
            <v-breadcrumbs :items="breadcrumb"/>
            <h1 v-text="'Search results'" class="text-center mb-10"/>
            <v-row class="justify-center">
                <v-col md="8">
                    <template v-if="articles.length">
                        <p class="text-muted">{{ articles.length }} results for "{{ query }}"</p>
                        <v-card v-for="(item, itemIdx) in articles" outlined elevation="0" :key="itemIdx" class="mb-5">
                            <v-card-title>
                                <router-link :to="{name: 'faq.show', params:{id: item.id}}" v-text="item.title"/>
                            </v-card-title>
                            <v-card-text v-text="item.text.replace(/(<([^>]+)>)/gi, '').substr(0, 100) + '...'"/>
                        </v-card>
                    </template>
                    <template v-else>
                        <p class="text-center">
                            No results for "{{ query }}". <router-link :to="{name: 'faq'}" v-text="'Browse knowledge base'"/>
                        </p>
                    </template>
                </v-col>
            </v-row>
        </div>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Faq from "../../models/Faq";

    export default {
        name: "FaqSearch",

        components: {FrontLayout},

        props: {
            query: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                breadcrumb: [
                    {
                        text: 'Home',
                        disabled: false,
                        href: this.$router.resolve({name: 'home'}).href,
                    },
                    {
                        text: 'Help',
                        disabled: false,
                        href: this.$router.resolve({name: 'faq'}).href,
                    },
                    {
                        text: 'Search: ' + this.query,
                        disabled: true,
                    }
                ],
                articles: {}
            }
        },

        async mounted() {
            await this.getArticles();
        },

        methods: {
            async getArticles() {
                this.articles = await Faq.where('search', this.query).get()
            }
        },
    }
</script>

<style lang="scss" scoped>
.FaqArticle {

}
</style>
